var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card card-custom gutter-b card-stretch"},[_c('div',{staticClass:"card-header h-auto border-0"},[_c('div',{staticClass:"card-title py-5"},[_c('h3',{staticClass:"card-label"},[_c('span',{staticClass:"d-block text-dark font-weight-bolder"},[_vm._v("İptaller")]),_c('span',{staticClass:"d-block text-muted pt-2 font-size-sm"},[_vm._v("Toplam "+_vm._s(_vm.total)+" adet rezervasyon")])])])]),_c('div',{staticClass:"card-body"},[_c('b-table',{staticClass:"table table-head-custom table-head-bg table-borderless table-vertical-center",attrs:{"thead-class":"mb-10","show-empty":"","empty-html":"\n        <div>\n          <img src=\"media/svg/illustrations/list-is-empty.svg\" style=\"width:30%\"/>\n          <label class=\"d-block font-weight-bold font-size-h6 text-muted\">Listelenecek herhangi bir rezervasyon bulunamadı.</label> \n        </div>","stacked":"lg","items":_vm.cancellations,"fields":_vm.fields,"current-page":_vm.currentPage,"per-page":_vm.perPage,"busy":_vm.isBusy},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-danger my-2"},[_c('b-spinner',{staticClass:"align-middle mr-5"}),_c('strong',[_vm._v("Yükleniyor...")])],1)]},proxy:true},{key:"cell(reservation)",fn:function(data){return [_c('div',{staticClass:"py-2 pl-0 max-w-400px"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"symbol symbol-60 symbol-2by3 flex-shrink-0 mr-4"},[_c('div',{staticClass:"symbol-label",style:({
                  backgroundImage: ("url(" + (data.item.product.image) + ")")
                })})]),_c('div',[_c('span',{staticClass:"text-dark-75 font-weight-bolder d-block mb-2 font-size-lg"},[_vm._v(_vm._s(data.item.product.title))]),_c('div',[_c('span',{staticClass:"text-muted font-weight-bold"},[_vm._v("ZNF-"+_vm._s(data.item.reservationNumber))])])])])])]}},{key:"cell(departureDate)",fn:function(data){return [_c('span',{staticClass:"text-dark-75 font-weight-bolder d-block font-size-lg"},[_vm._v(" "+_vm._s(_vm.renderDateTime(data.item.departureDateTime, data.item.endDateTime))+" ")])]}},{key:"cell(pax)",fn:function(data){return [_c('span',{staticClass:"text-dark-75 font-weight-bolder d-block font-size-lg"},[_vm._v(_vm._s(data.item.pax)+" Pax")])]}},{key:"cell(price)",fn:function(data){return [_c('span',{staticClass:"text-danger font-weight-bolder font-size-lg pr-1"},[_vm._v(_vm._s(data.item.price.value))]),_c('span',{staticClass:"text-danger font-weight-bold font-size-lg"},[_vm._v(_vm._s(data.item.price.currency))])]}},{key:"cell(actions)",fn:function(data){return [_c('router-link',{attrs:{"to":{
            name: 'reservation-details',
            params: {
              id: data.item.bookingId,
              reservationNumber: data.item.reservationNumber
            }
          }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var href = ref.href;
return [_c('a',{staticClass:"btn btn-icon btn-light btn-hover-primary btn-sm",attrs:{"href":href}},[_c('span',{staticClass:"svg-icon svg-icon-md svg-icon-primary"},[_c('inline-svg',{attrs:{"src":"media/svg/icons/Navigation/Arrow-right.svg"}})],1)])]}}],null,true)})]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }