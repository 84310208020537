<template>
  <div class="row">
    <div class="col-xl-3">
      <div class="card card-custom bg-light gutter-b" :class="{'bg-light-white': !isError}" style="height: 100px;">
        <div class="card-body d-flex flex-row p-0 align-items-center" style="position: relative;">
          <div class="flex-grow-1 card-spacer-x">
            <div class="text-muted font-weight-bold font-size-lg">Brüt Gelir</div>
            <div class="font-weight-bolder font-size-h3 pt-2">{{ totalIncomeText }}</div>
          </div>
          <div class="pr-6">
             <div class="symbol symbol-light-info mr-3">
                <span class="symbol-label">
                  <span class="svg-icon svg-icon-info svg-icon-xl">
                    <inline-svg src="media/svg/icons/Shopping/Wallet.svg"/>
                  </span>
                </span>
              </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-3">
      <div class="card card-custom bg-light gutter-b" :class="{'bg-light-white': !isError}" style="height: 100px;">
        <div class="card-body d-flex flex-row p-0 align-items-center" style="position: relative;">
          <div class="flex-grow-1 card-spacer-x">
            <div class="text-muted font-weight-bold font-size-lg">Rezervasyon</div>
            <div class="font-weight-bolder font-size-h3 pt-2">{{ totalReservationText }}</div>
          </div>
          <div class="pr-6">
             <div class="symbol symbol-light-success mr-3">
                <span class="symbol-label">
                  <span class="svg-icon svg-icon-success svg-icon-xl">
                    <inline-svg src="media/svg/icons/Shopping/Cart2.svg"/>
                  </span>
                </span>
              </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-3">
      <div class="card card-custom bg-light gutter-b" :class="{'bg-light-white': !isError}" style="height: 100px">
        <div class="card-body d-flex flex-row p-0 align-items-center" style="position: relative;">
          <div class="flex-grow-1 card-spacer-x">
            <div class="text-muted font-weight-bold font-size-lg">Toplam Yolcu</div>
            <div class="font-weight-bolder font-size-h3 pt-2">{{ totalPaxText }}</div>
          </div>
          <div class="pr-6">
             <div class="symbol symbol-light-warning mr-3">
                <span class="symbol-label">
                  <span class="svg-icon svg-icon-warning svg-icon-xl">
                    <inline-svg src="media/svg/icons/Communication/Group.svg"/>
                  </span>
                </span>
              </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-3">
      <div class="card card-custom bg-light gutter-b" :class="{'bg-light-white': !isError}" style="height: 100px">
        <div class="card-body d-flex flex-row p-0 align-items-center" style="position: relative;">
          <div class="flex-grow-1 card-spacer-x">
            <div class="text-muted font-weight-bold font-size-lg">İptal</div>
            <div class="font-weight-bolder font-size-h3 pt-2">{{ totalCancellationText }}</div>
          </div>
          <div class="pr-6">
             <div class="symbol symbol-light-danger mr-3">
                <span class="symbol-label">
                  <span class="svg-icon svg-icon-danger svg-icon-xl">
                    <inline-svg src="media/svg/icons/Communication/Spam.svg"/>
                  </span>
                </span>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from '@/core/services/api.service';
export default {
  data () {
    return {
      totalIncome: '',
      totalPax: 0,
      totalReservation: 0,
      totalCancellation: 0,
      isError: false
    }
  },
  computed: {
    totalIncomeText() {
      if (this.totalIncome == '') {
        return '-';
      } else {
        return this.totalIncome;
      }
    },
    totalPaxText() {
      if (this.totalPax > 0) {
        return this.totalPax + ' Pax';
      } else {
        return '-'
      }
    },
    totalReservationText() {
      if (this.totalReservation > 0) {
        return this.totalReservation + ' Adet';
      } else {
        return '-'
      }
    },
    totalCancellationText() {
      if (this.totalCancellation > 0) {
        return this.totalCancellation + ' Adet';
      } else {
        return '-'
      }
    }
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      ApiService.get('dashboard/overall/get.req.php')
      .then(({ data }) => {
        this.totalIncome = data.overall.totalIncome;
        this.totalReservation = data.overall.totalReservation;
        this.totalPax = data.overall.totalPax;
        this.totalCancellation = data.overall.totalCancellation;
        this.isError = false;
      })
      .catch(() => {
        this.isError = true;
      });
    }
  }
}
</script>

<style>

</style>