<template>
  <div class="card card-custom gutter-b card-stretch">
    <!--begin::Header-->
    <div class="card-header h-auto border-0">
      <!--begin::Title-->
      <div class="card-title py-5">
        <h3 class="card-label">
          <span class="d-block text-dark font-weight-bolder"
            >Son Gelen Rezervasyonlar</span
          >
          <span class="d-block text-dark-50 mt-2 font-size-sm"
            v-if="false">More than 500+ new orders</span
          >
        </h3>
      </div>
      <div class="card-toolbar">
        <router-link :to="{ name: 'reservations' }" v-slot="{ href }">
          <a :href="href" class="btn btn-light-primary font-weight-bolder font-size-sm">
            Tüm Rezervasyonlar
          </a>
        </router-link>
      </div>
      <!--end::Title-->
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body">
      <b-table
        class="table table-head-custom table-head-bg table-borderless table-vertical-center"
        thead-class="mb-10"
        show-empty
        empty-html='
          <div>
            <img src="media/svg/illustrations/list-is-empty.svg" style="width:30%"/>
            <label class="d-block font-weight-bold font-size-h6 text-muted">Listelenecek herhangi bir rezervasyon bulunamadı.</label> 
          </div>'
        stacked="lg"
        :items="reservations"
        :fields="reservationsDataTableFields"
        :current-page="currentPage"
        :per-page="perPage"
        :busy="isBusy"
      >
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle mr-5"></b-spinner>
            <strong>Yükleniyor...</strong>
          </div>
        </template>
        <template #cell(reservationNumber)="data">
          <div class="py-2 pl-0 max-w-400px">
            <div class="d-flex align-items-center">
              <div class="symbol symbol-60 symbol-2by3 flex-shrink-0 mr-4">
                <div class="symbol-label" :style="{ backgroundImage: `url(${data.item.product.image})` }"></div>
              </div>
              <div>
                <span class="text-dark-75 font-weight-bolder d-block mb-2 font-size-lg">{{ data.item.product.name }}</span>
                <div>
                  <span class="text-muted font-weight-bold">ZNF-{{ data.item.reservationNumber }}</span>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template #cell(dateTime)="data">
          <span class="text-dark-75 font-weight-bolder d-block font-size-lg">
            {{ renderDateTime(data.item.departureDateTime, data.item.endDateTime) }}
          </span>
        </template>
        <template #cell(pax)="data">
          <span class="text-dark-75 font-weight-bolder d-block font-size-lg">{{ data.item.pax }} Pax</span>
        </template>
        <template #cell(price)="data">
          <span class="text-dark-75 font-weight-bolder font-size-lg pr-1">{{ data.item.price.amount }}</span>
          <span class="text-dark-75 font-weight-bold font-size-lg">{{ data.item.price.currency }}</span>
        </template>
        <template #cell(status)="data">
          <span class="label label-lg label-success font-weight-bold label-inline" v-if="data.item.status == 1">Onaylandı</span>
          <span class="label label-lg label-danger font-weight-bold label-inline" v-if="data.item.status == 0">İptal Edildi</span>
        </template>
        <template #cell(actions)="data">
          <router-link
            :to="{ name: 'reservation-details', params: { id: data.item.id, reservationNumber: data.item.reservationNumber }}"
            v-slot="{ href }"
          >
            <a :href="href" class="btn btn-icon btn-light btn-hover-primary btn-sm">
              <span class="svg-icon svg-icon-md svg-icon-primary">
                <inline-svg src="media/svg/icons/Navigation/Arrow-right.svg"/>
              </span>
            </a>
          </router-link>
        </template>
      </b-table>
    </div>
    <!--end::Body-->
  </div>
</template>

<script>
import moment from 'moment';
import ApiService from '@/core/services/api.service';

export default {
  data() {
    return {
      reservations: [],
      reservationsDataTableFields: [
        { key: 'reservationNumber', label: 'REZERVASYON', sortable: false },
        { key: 'dateTime', label: 'HAREKET TARİHİ', sortable: false },
        { key: 'pax', label: 'PAX', sortable: false, class: 'text-right' },
        { key: 'price', label: 'FİYAT', sortable: false, class: 'text-right' },
        { key: 'status', label: '', sortable: false, class: 'text-right' },
        { key: 'actions', label: '', sortable: false, class: 'text-right' },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      isBusy: false
    }
  },
  mounted() {
    moment.locale('tr');
    this.get();
  },
  methods: {
    get() {
      this.isBusy = true;

      ApiService.get('dashboard/reservations/get.req.php')
      .then(({ data }) => {
        this.reservations = data.reservations;
        this.totalRows = this.reservations.length;
        setTimeout(() => {
          this.isBusy = false;  
          this.$emit('total-rows', this.totalRows);
        }, 500);
      })
      .catch(() => {
        this.reservations = [];
        this.totalRows = 0;
        setTimeout(() => {
          this.isBusy = false;  
        }, 500);
      });
    },
    renderDateTime(departureDate, endDate) {
      let departure = moment(departureDate, 'YYYY-MM-DD HH:mm:ss');
      let end = moment(endDate, 'YYYY-MM-DD HH:mm:ss');
      
      if (moment(departure.format('YYYY-MM-DD')).isSame(end.format('YYYY-MM-DD'))) {
        return departure.format('D MMM, HH:mm');
      } else {
        if (moment(departure.format('YYYY-MM')).isSame(end.format('YYYY-MM'))) {
          return departure.format('D-') + end.format('D MMM');
        } else {
          return departure.format('D MMM - ') + end.format('D MMM');
        }
      }
    },
  }
}
</script>

<style>

</style>